<template>
  <section id="header" style="background-color: black">
    <nav class="navbar navbar-expand-md navbar-light" id="navbar_sticky">
      <div class="container-xl">
        <a class="navbar-brand fs-3 p-0 fw-bold text-white" href="/"
          ><i class="fa fa-car col_oran me-1 fs-2 align-middle"></i> Kigali
          Rwanda Car Hire
          <!-- <br /> -->
          <span class="fs-5 span_1">...</span></a
        >
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mb-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/about">About </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/car-listing">Car Models </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/contact">Contact</a>
            </li>
          </ul>
          <ul class="navbar-nav mb-0 ms-auto">
            <li class="nav-item">
              <a class="nav-link" href="#">Sign In</a>
            </li>
            <li class="nav-item">
              <a class="nav-link button_2 ms-2 me-2" href="#"
                >Register <i class="fa fa-check-circle ms-1"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </section>
</template>
