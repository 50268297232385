<template>
  <div class="main clearfix position-relative">
    <div class="main_1 clearfix position-absolute top-0 w-100">
      <HeaderItem />
    </div>
    <div class="main_2 clearfix">
      <section id="center" class="center_home">
        <div
          id="carouselExampleCaptions"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              class="active"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
              class=""
              aria-current="true"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="img/red-car.jpeg" class="d-block w-100" alt="..." />
              <div class="carousel-caption d-md-block">
                <h5 style="color: black;">Plan your trip now</h5>
                <h1 class="font_50 mt-4" style="color: black;">
                  Save <span class="col_oran">big</span> with our <br />
                  car rental
                </h1>
                <!-- <p class="mt-4 mb-4">
                  To contribute to positive change and achieve our
                  sustainability goals with many extraordinary
                </p> -->
                <br /><br />
                <h6 class="d-inline-block me-2 mb-0">
                  <a class="button" href="#"
                    >Book Ride <i class="fa fa-check-circle ms-1"></i>
                  </a>
                </h6>
                <h6 class="d-inline-block mb-0">
                  <!-- <a class="button_1" href="#"
                    >Learn More <i class="fa fa-check-circle ms-1"></i>
                  </a> -->
                </h6>
              </div>
            </div>
            <div class="carousel-item">
              <img src="img/toyotav8-2.jpeg" class="d-block w-100" alt="..." />
              <div class="carousel-caption d-md-block">
                <h5>Plan your trip now</h5>
                <h1 class="font_50 mt-4">
                  Best <span class="col_oran">Valued</span> deals you <br />
                  will ever find
                </h1>
                <!-- <p class="mt-4 mb-4">
                  To contribute to positive change and achieve our
                  sustainability goals with many extraordinary
                </p> -->
                <br /><br />
                <h6 class="d-inline-block me-2 mb-0">
                  <a class="button" href="#"
                    >Book Ride <i class="fa fa-check-circle ms-1"></i>
                  </a>
                </h6>
                <h6 class="d-inline-block mb-0">
                  <!-- <a class="button_1" href="#"
                    >Learn More <i class="fa fa-check-circle ms-1"></i>
                  </a> -->
                </h6>
              </div>
            </div>
            <div class="carousel-item">
              <img src="img/landcruiser.jpeg" class="d-block w-100" alt="..." />
              <div class="carousel-caption d-md-block">
                <h5 style="color: black;">Plan your trip now</h5>
                <h1 class="font_50 mt-4" style="color: black;">
                  We are <span class="col_oran">Local</span> Car Rental <br />
                  24/7 Support
                </h1>
                <!-- <p class="mt-4 mb-4">
                  To contribute to positive change and achieve our
                  sustainability goals with many extraordinary
                </p> -->
                <br /><br />
                <h6 class="d-inline-block me-2 mb-0">
                  <a class="button" href="#"
                    >Book Ride <i class="fa fa-check-circle ms-1"></i>
                  </a>
                </h6>
                <h6 class="d-inline-block mb-0">
                  <!-- <a class="button_1" href="#"
                    >Learn More <i class="fa fa-check-circle ms-1"></i>
                  </a> -->
                </h6>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>
    </div>
    <div class="main_3 position-absolute w-100 clearfix">
      <section id="booking">
        <div class="container-xl">
          <div class="booking_m clearfix bg-white">
            <div class="row booking_1">
              <div class="col-md-12">
                <h4 class="mb-0">Book a car</h4>
              </div>
            </div>
            <div class="row booking_2 mt-4">
              <div class="col-md-4 col-sm-6">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-car me-1 col_oran"></i> Select Your Car Type
                  </h6>
                  <select class="form-select" id="example-select">
                    <option>Select your car type</option>
                    <option>Mercedes</option>
                    <option>Lexus CT</option>
                    <option>VW PassatCC</option>
                    <option>Toyota</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 col-sm-6">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-map-marker me-1 col_oran"></i> Pick-up
                  </h6>
                  <select class="form-select" id="example-select">
                    <option>Delhi road</option>
                    <option>Mumbai city</option>
                    <option>London</option>
                    <option>Brisbane</option>
                    <option>Pearth</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-map-marker me-1 col_oran"></i> Drop-of
                  </h6>
                  <select class="form-select" id="example-select">
                    <option>Delhi road</option>
                    <option>Mumbai city</option>
                    <option>London</option>
                    <option>Brisbane</option>
                    <option>Pearth</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row booking_2 mt-4">
              <div class="col-md-4 col-sm-6">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-calendar me-1 col_oran"></i> Pick-up
                  </h6>
                  <div class="booking_2i1 row">
                    <div class="col-md-8">
                      <div class="booking_2i1l">
                        <input
                          class="form-control"
                          id="example-date"
                          type="date"
                          name="date"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 ps-0">
                      <div class="booking_2i1r">
                        <select class="form-select" id="example-select">
                          <option value="12:00 AM">12:00 AM</option>
                          <option value="12:30 AM">12:30 AM</option>
                          <option value="01:00 AM">01:00 AM</option>
                          <option value="01:30 AM">01:30 AM</option>
                          <option value="02:00 AM">02:00 AM</option>
                          <option value="02:30 AM">02:30 AM</option>
                          <option value="03:00 AM">03:00 AM</option>
                          <option value="03:30 AM">03:30 AM</option>
                          <option value="04:00 AM">04:00 AM</option>
                          <option value="04:30 AM">04:30 AM</option>
                          <option value="05:00 AM">05:00 AM</option>
                          <option value="05:30 AM">05:30 AM</option>
                          <option value="06:00 AM">06:00 AM</option>
                          <option value="06:30 AM">06:30 AM</option>
                          <option value="07:00 AM">07:00 AM</option>
                          <option value="07:30 AM">07:30 AM</option>
                          <option value="08:00 AM">08:00 AM</option>
                          <option value="08:30 AM">08:30 AM</option>
                          <option value="09:00 AM">09:00 AM</option>
                          <option value="09:30 AM">09:30 AM</option>
                          <option value="10:00 AM">10:00 AM</option>
                          <option value="10:30 AM">10:30 AM</option>
                          <option value="11:00 AM">11:00 AM</option>
                          <option value="11:30 AM">11:30 AM</option>
                          <option value="12:00 PM">12:00 PM</option>
                          <option value="12:30 PM">12:30 PM</option>
                          <option value="01:00 PM">01:00 PM</option>
                          <option value="01:30 PM">01:30 PM</option>
                          <option value="02:00 PM">02:00 PM</option>
                          <option value="02:30 PM">02:30 PM</option>
                          <option value="03:00 PM">03:00 PM</option>
                          <option value="03:30 PM">03:30 PM</option>
                          <option value="04:00 PM">04:00 PM</option>
                          <option value="04:30 PM">04:30 PM</option>
                          <option value="05:00 PM">05:00 PM</option>
                          <option value="05:30 PM">05:30 PM</option>
                          <option value="06:00 PM">06:00 PM</option>
                          <option value="06:30 PM">06:30 PM</option>
                          <option value="07:00 PM">07:00 PM</option>
                          <option value="07:30 PM">07:30 PM</option>
                          <option value="08:00 PM">08:00 PM</option>
                          <option value="08:30 PM">08:30 PM</option>
                          <option value="09:00 PM">09:00 PM</option>
                          <option value="09:30 PM">09:30 PM</option>
                          <option value="10:00 PM">10:30 PM</option>
                          <option value="10:30 PM">12:00 AM</option>
                          <option value="11:00 PM">11:00 PM</option>
                          <option value="11:30 PM">11:30 PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-calendar me-1 col_oran"></i> Drop-of
                  </h6>
                  <div class="booking_2i1 row">
                    <div class="col-md-8">
                      <div class="booking_2i1l">
                        <input
                          class="form-control"
                          id="example-date"
                          type="date"
                          name="date"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 ps-0">
                      <div class="booking_2i1r">
                        <select class="form-select" id="example-select">
                          <option value="12:00 AM">12:00 AM</option>
                          <option value="12:30 AM">12:30 AM</option>
                          <option value="01:00 AM">01:00 AM</option>
                          <option value="01:30 AM">01:30 AM</option>
                          <option value="02:00 AM">02:00 AM</option>
                          <option value="02:30 AM">02:30 AM</option>
                          <option value="03:00 AM">03:00 AM</option>
                          <option value="03:30 AM">03:30 AM</option>
                          <option value="04:00 AM">04:00 AM</option>
                          <option value="04:30 AM">04:30 AM</option>
                          <option value="05:00 AM">05:00 AM</option>
                          <option value="05:30 AM">05:30 AM</option>
                          <option value="06:00 AM">06:00 AM</option>
                          <option value="06:30 AM">06:30 AM</option>
                          <option value="07:00 AM">07:00 AM</option>
                          <option value="07:30 AM">07:30 AM</option>
                          <option value="08:00 AM">08:00 AM</option>
                          <option value="08:30 AM">08:30 AM</option>
                          <option value="09:00 AM">09:00 AM</option>
                          <option value="09:30 AM">09:30 AM</option>
                          <option value="10:00 AM">10:00 AM</option>
                          <option value="10:30 AM">10:30 AM</option>
                          <option value="11:00 AM">11:00 AM</option>
                          <option value="11:30 AM">11:30 AM</option>
                          <option value="12:00 PM">12:00 PM</option>
                          <option value="12:30 PM">12:30 PM</option>
                          <option value="01:00 PM">01:00 PM</option>
                          <option value="01:30 PM">01:30 PM</option>
                          <option value="02:00 PM">02:00 PM</option>
                          <option value="02:30 PM">02:30 PM</option>
                          <option value="03:00 PM">03:00 PM</option>
                          <option value="03:30 PM">03:30 PM</option>
                          <option value="04:00 PM">04:00 PM</option>
                          <option value="04:30 PM">04:30 PM</option>
                          <option value="05:00 PM">05:00 PM</option>
                          <option value="05:30 PM">05:30 PM</option>
                          <option value="06:00 PM">06:00 PM</option>
                          <option value="06:30 PM">06:30 PM</option>
                          <option value="07:00 PM">07:00 PM</option>
                          <option value="07:30 PM">07:30 PM</option>
                          <option value="08:00 PM">08:00 PM</option>
                          <option value="08:30 PM">08:30 PM</option>
                          <option value="09:00 PM">09:00 PM</option>
                          <option value="09:30 PM">09:30 PM</option>
                          <option value="10:00 PM">10:30 PM</option>
                          <option value="10:30 PM">12:00 AM</option>
                          <option value="11:00 PM">11:00 PM</option>
                          <option value="11:30 PM">11:30 PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="booking_2i">
                  <h6 class="mb-3">
                    <i class="fa fa-search me-1 col_oran"></i> Find
                  </h6>
                  <h6 class="text-center mb-0">
                    <a class="button pt-3 pb-3 d-block" href="#">Search</a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <section id="trip" class="p_3">
    <div class="container-xl">
      <div class="row trip_1 text-center mb-4">
        <div class="col-md-12">
          <h5 class="col_oran">Plan your trip now</h5>
          <h1 class="mb-0">Quick & easy car rental</h1>
        </div>
      </div>
      <div class="row trip_2">
        <div class="col-md-4">
          <div class="trip_2i text-center">
            <span class="d-inline-block fs-1"
              ><a class="col_oran" href="#"><i class="fa fa-car"></i></a
            ></span>
            <h3 class="mt-3"><a href="#">Select Car</a></h3>
            <p class="mb-0 mt-3">
              Select a Car You want , we will Give it Back to You Immediately
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="trip_2i text-center">
            <span class="d-inline-block fs-1"
              ><a class="col_oran" href="#"><i class="fa fa-user-plus"></i></a
            ></span>
            <h3 class="mt-3"><a href="#">Contact Operator</a></h3>
            <p class="mb-0 mt-3">
              After Selecting a Car Contact the Operator Or Book a Rider to
              Whatsaap , we will get back to you like a flash
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="trip_2i text-center">
            <span class="d-inline-block fs-1"
              ><a class="col_oran" href="#"><i class="fa fa-car"></i></a
            ></span>
            <h3 class="mt-3"><a href="#">Let's Drive</a></h3>
            <p class="mb-0 mt-3">
              To contribute to positive change and achieve our sustainability
              goals with many extraordinary
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="model_pg" class="p_3">
    <div class="container-xl">
      <div class="row model_pg1">
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a href="#"
                    ><img
                      src="img/27d309b3-2bf2-49bf-84af-397d26e422ca.jpeg"
                      class="w-100"
                      alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Toyota v 8</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6><i class="fa fa-car col_oran me-1"></i> Sedan</h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <h6>
                      <i class="fa fa-suitcase col_oran me-1"></i> 2 Luggage
                    </h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-female col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
              </div>
              <hr />

              <div class="model_pg1i4 row text-center mt-4">
                <div class="col-md-12">
                  <h6 class="mb-0">
                    <a class="button" href="#"
                      >Book Ride <i class="fa fa-check-circle ms-1"></i>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a href="#"
                    ><img
                      src="img/02f18275-7609-4f7a-932c-3bf0b5c6e999.jpeg"
                      class="w-100"
                      alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Toyota v8 Black</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6><i class="fa fa-car col_oran me-1"></i> Sedan</h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <h6>
                      <i class="fa fa-suitcase col_oran me-1"></i> 2 Luggage
                    </h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-female col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
              </div>
              <hr />

              <div class="model_pg1i4 row text-center mt-4">
                <div class="col-md-12">
                  <h6 class="mb-0">
                    <a class="button" href="#"
                      >Book Ride <i class="fa fa-check-circle ms-1"></i>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a href="#"
                    ><img
                      src="img/4b57cb87-83f9-44fb-b384-5541f8150b3f.jpeg"
                      class="w-100"
                      alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Toyota Hilux</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6><i class="fa fa-car col_oran me-1"></i> Sedan</h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <h6>
                      <i class="fa fa-suitcase col_oran me-1"></i> 2 Luggage
                    </h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-female col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
              </div>
              <hr />

              <div class="model_pg1i4 row text-center mt-4">
                <div class="col-md-12">
                  <h6 class="mb-0">
                    <a class="button" href="#"
                      >Book Ride <i class="fa fa-check-circle ms-1"></i>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a href="#"
                    ><img src="img/car1.jpeg" class="w-100" alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Land Cruiser</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6><i class="fa fa-car col_oran me-1"></i> Sedan</h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <h6>
                      <i class="fa fa-suitcase col_oran me-1"></i> 2 Luggage
                    </h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-female col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
              </div>
              <hr />

              <div class="model_pg1i4 row text-center mt-4">
                <div class="col-md-12">
                  <h6 class="mb-0">
                    <a class="button" href="#"
                      >Book Ride <i class="fa fa-check-circle ms-1"></i>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a href="#"
                    ><img src="img/car3prado.jpeg" class="w-100" alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Prado</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6><i class="fa fa-car col_oran me-1"></i> Sedan</h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <h6>
                      <i class="fa fa-suitcase col_oran me-1"></i> 2 Luggage
                    </h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-female col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
              </div>
              <hr />

              <div class="model_pg1i4 row text-center mt-4">
                <div class="col-md-12">
                  <h6 class="mb-0">
                    <a class="button" href="#"
                      >Book Ride <i class="fa fa-check-circle ms-1"></i>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a href="#"
                    ><img src="img/car4.jpeg" class="w-100" alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Prado Black</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6><i class="fa fa-car col_oran me-1"></i> Sedan</h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <h6>
                      <i class="fa fa-suitcase col_oran me-1"></i> 2 Luggage
                    </h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-female col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
              </div>
              <hr />

              <div class="model_pg1i4 row text-center mt-4">
                <div class="col-md-12">
                  <h6 class="mb-0">
                    <a class="button" href="#"
                      >Book Ride <i class="fa fa-check-circle ms-1"></i>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row model_pg1 mt-4">
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a href="#"
                    ><img src="img/car5.jpeg" class="w-100" alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Kompressor</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6><i class="fa fa-car col_oran me-1"></i> Sedan</h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <h6>
                      <i class="fa fa-suitcase col_oran me-1"></i> 2 Luggage
                    </h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-female col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
              </div>
              <hr />

              <div class="model_pg1i4 row text-center mt-4">
                <div class="col-md-12">
                  <h6 class="mb-0">
                    <a class="button" href="#"
                      >Book Ride <i class="fa fa-check-circle ms-1"></i>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a href="#"
                    ><img src="img/car2prado.jpeg" class="w-100" alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Prado</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6><i class="fa fa-car col_oran me-1"></i> Sedan</h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <h6>
                      <i class="fa fa-suitcase col_oran me-1"></i> 2 Luggage
                    </h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-female col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
              </div>
              <hr />

              <div class="model_pg1i4 row text-center mt-4">
                <div class="col-md-12">
                  <h6 class="mb-0">
                    <a class="button" href="#"
                      >Book Ride <i class="fa fa-check-circle ms-1"></i>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="model_pg1i clearfix">
            <div class="model_pg1i1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a href="#"
                    ><img
                      src="img/88c39049-47ac-40c8-8cc9-a06c72a65b1b.jpeg"
                      class="w-100"
                      alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="model_m p-3 clearfix border-top-0">
              <div class="model_pg1i2 row">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i2l">
                    <h4>Toyota Hybrid</h4>
                  </div>
                </div>
              </div>
              <div class="model_pg1i3 row mt-4 mb-4">
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l">
                    <h6><i class="fa fa-car col_oran me-1"></i> Sedan</h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-male col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="model_pg1i3l text-end">
                    <h6>
                      <i class="fa fa-suitcase col_oran me-1"></i> 2 Luggage
                    </h6>
                    <h6 class="mb-0 mt-3">
                      <i class="fa fa-female col_oran me-1"></i> 6 Seats
                    </h6>
                  </div>
                </div>
              </div>
              <hr />

              <div class="model_pg1i4 row text-center mt-4">
                <div class="col-md-12">
                  <h6 class="mb-0">
                    <a class="button" href="#"
                      >Book Ride <i class="fa fa-check-circle ms-1"></i>
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="about_h" class="p_3 bg_light">
    <div class="container-xl">
      <div class="row trip_1 text-center mb-4">
        <div class="col-md-12">
          <h5 class="col_oran">Vehicle Models</h5>
          <h1>Our rental fleet</h1>
          <p class="mb-0">
            To contribute to positive change and achieve our sustainability
            <br />
            goals with many extraordinary
          </p>
        </div>
      </div>
      <div class="row about_h1">
        <div class="col-md-5">
          <div class="about_h1l clearfix position-relative">
            <div class="clearfix about_h1l1">
              <div class="grid clearfix">
                <figure class="effect-jazz mb-0">
                  <a href="#"
                    ><img
                      src="img/02f18275-7609-4f7a-932c-3bf0b5c6e999.jpeg"
                      class="w-100"
                      alt="abc"
                  /></a>
                </figure>
              </div>
            </div>
            <div class="clearfix about_h1l2 position-absolute">
              <span class="d-inline-block bg_oran text-center fs-3"
                ><a class="text-white d-block" href="#"
                  ><i class="fa fa-play"></i></a
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="about_h1r">
            <h5 class="col_oran">About Company</h5>
            <h1>
              You start the engine <br />
              and your adventure <br />
              begins
            </h1>
            <p class="mt-3">
              Certain but she but shyness why cottage. Guy the put instrument
              sir entreaties affronting. Pretended exquisite see cordially the
              you. Weeks quiet do vexed or whose. Motionless if no to affronting
              imprudence no precaution. My indulged as disposal strongly
              attended.
            </p>
            <hr />
            <div class="about_h1ri row">
              <div class="col-md-4 col-sm-4">
                <div class="about_h1ri1">
                  <span class="font_50 col_oran"
                    ><i class="fa fa-car"></i
                  ></span>
                  <h1>22</h1>
                  <h6 class="col_light fw-normal mb-0">Car Types</h6>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="about_h1ri1">
                  <span class="font_50 col_oran"
                    ><i class="fa fa-building"></i
                  ></span>
                  <h1>88</h1>
                  <h6 class="col_light fw-normal mb-0">Rental Outlets</h6>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="about_h1ri1">
                  <span class="font_50 col_oran"
                    ><i class="fa fa-shirtsinbulk"></i
                  ></span>
                  <h1>77</h1>
                  <h6 class="col_light fw-normal mb-0">Repair Shop</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="ride">
    <div class="ride_m">
      <div class="container-xl">
        <div class="row ride_1">
          <div class="col-md-8">
            <div class="ride_1l">
              <h1 class="text-white">
                Save big with our cheap <br />
                car rental!
              </h1>
              <p class="text-light mb-0 fs-4 mt-3">
                Top Airports. Local Suppliers. 24/7 Support.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="ride_1r mt-4 text-end">
              <h6 class="mb-0">
                <a class="button_2" href="#"
                  >Book Ride <i class="fa fa-check-circle ms-1"></i>
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="choose" class="p_3">
    <div class="container-xl">
      <div class="row choose_1">
        <div class="col-md-7">
          <div class="choose_1l">
            <h5 class="col_oran">Why Choose Us</h5>
            <h1>
              Best valued deals you <br />
              will ever find
            </h1>
            <p class="mt-3">
              Thrown shy denote ten ladies though ask saw. Or by to he going
              think order event music. Incommode so intention defective at
              convinced. Led income months itself and houses you.
            </p>
            <h6 class="mb-0 mt-4">
              <a class="button" href="#"
                >Find Deals <i class="fa fa-check-circle ms-1"></i>
              </a>
            </h6>
          </div>
        </div>
        <div class="col-md-5">
          <div class="choose_1r">
            <div class="choose_1ri row">
              <div class="col-md-3">
                <div class="choose_1ril">
                  <span class="fs-1 d-inline-block text-center col_oran"
                    ><i class="fa fa-car"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-9">
                <div class="choose_1rir">
                  <h4>Cross Country Drive</h4>
                  <p class="mb-0 mt-3">
                    Speedily say has suitable disposal add boy. On forth doubt
                    miles of child. Exercise joy man children rejoiced.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="choose_1ri row mt-3">
              <div class="col-md-3">
                <div class="choose_1ril">
                  <span class="fs-1 d-inline-block text-center col_oran"
                    ><i class="fa fa-dollar"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-9">
                <div class="choose_1rir">
                  <h4>All Inclusive Pricing</h4>
                  <p class="mb-0 mt-3">
                    Speedily say has suitable disposal add boy. On forth doubt
                    miles of child. Exercise joy man children rejoiced.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="choose_1ri row mt-3">
              <div class="col-md-3">
                <div class="choose_1ril">
                  <span class="fs-1 d-inline-block text-center col_oran"
                    ><i class="fa fa-rupee"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-9">
                <div class="choose_1rir">
                  <h4>No Hidden Charges</h4>
                  <p class="mb-0 mt-3">
                    Speedily say has suitable disposal add boy. On forth doubt
                    miles of child. Exercise joy man children rejoiced.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="testim" class="p_3 bg_light">
    <div class="container-xl">
      <div class="row trip_1 text-center mb-4">
        <div class="col-md-12">
          <h5 class="col_oran">Reviewed by People</h5>
          <h1>Clients' Testimonials</h1>
          <p class="mb-0">
            To contribute to positive change and achieve our sustainability
            <br />
            goals with many extraordinary
          </p>
        </div>
      </div>
      <div class="row testim_1">
        <div
          id="carouselExampleCaptions2"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions2"
              data-bs-slide-to="0"
              class="active"
              aria-label="Slide 1"
              aria-current="true"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions2"
              data-bs-slide-to="1"
              aria-label="Slide 2"
              class=""
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="testim_1i row">
                <div class="col-md-6">
                  <div
                    class="testim_1i1 text-center p-4 pt-5 pb-5 bg-white rounded"
                  >
                    <img src="img/6.jpg" alt="abc" class="rounded-circle" />
                    <h4 class="mt-3">Semp Porta</h4>
                    <h6 class="fw-normal col_light">CEO, Company Inc.</h6>
                    <span class="font_50"
                      ><i class="fa fa-quote-left"></i
                    ></span>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Eos aperiam porro necessitatibus, consequuntur, reiciendis
                      dolore doloribus id repellendus tempora vitae quia
                      voluptas ipsum eligendi hic.
                    </p>
                    <span class="col_oran">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star-half-o"></i>
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="testim_1i1 text-center p-4 pt-5 pb-5 bg-white rounded"
                  >
                    <img src="img/7.jpg" alt="abc" class="rounded-circle" />
                    <h4 class="mt-3">Eget Nulla</h4>
                    <h6 class="fw-normal col_light">CEO, Company Inc.</h6>
                    <span class="font_50"
                      ><i class="fa fa-quote-left"></i
                    ></span>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Eos aperiam porro necessitatibus, consequuntur, reiciendis
                      dolore doloribus id repellendus tempora vitae quia
                      voluptas ipsum eligendi hic.
                    </p>
                    <span class="col_oran">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star-half-o"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="testim_1i row">
                <div class="col-md-6">
                  <div
                    class="testim_1i1 text-center p-4 pt-5 pb-5 bg-white rounded"
                  >
                    <img src="img/8.jpg" alt="abc" class="rounded-circle" />
                    <h4 class="mt-3">Dapibus Diam</h4>
                    <h6 class="fw-normal col_light">CEO, Company Inc.</h6>
                    <span class="font_50"
                      ><i class="fa fa-quote-left"></i
                    ></span>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Eos aperiam porro necessitatibus, consequuntur, reiciendis
                      dolore doloribus id repellendus tempora vitae quia
                      voluptas ipsum eligendi hic.
                    </p>
                    <span class="col_oran">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star-half-o"></i>
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="testim_1i1 text-center p-4 pt-5 pb-5 bg-white rounded"
                  >
                    <img src="img/9.jpg" alt="abc" class="rounded-circle" />
                    <h4 class="mt-3">Per Conubia</h4>
                    <h6 class="fw-normal col_light">CEO, Company Inc.</h6>
                    <span class="font_50"
                      ><i class="fa fa-quote-left"></i
                    ></span>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Eos aperiam porro necessitatibus, consequuntur, reiciendis
                      dolore doloribus id repellendus tempora vitae quia
                      voluptas ipsum eligendi hic.
                    </p>
                    <span class="col_oran">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star-half-o"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="faq" class="p_3">
    <div class="container-xl">
      <div class="row trip_1 text-center mb-4">
        <div class="col-md-12">
          <h5 class="col_oran">FAQ</h5>
          <h1>Frequently Asked Questions</h1>
          <p class="mb-0">
            To contribute to positive change and achieve our sustainability
            <br />
            goals with many extraordinary
          </p>
        </div>
      </div>
      <div class="row faq_1">
        <div class="col-md-6">
          <div class="faq_1l">
            <div class="grid clearfix">
              <figure class="effect-jazz mb-0">
                <a href="#"
                  ><img src="img/faq.jpeg" class="w-100" alt="abc"
                /></a>
              </figure>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="faq_1r">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button mt-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What is special about comparing rental car deals?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Use securing confined his shutters. Delightful as he it
                    acceptance an solicitude discretion reasonably. Carriage we
                    husbands advanced an perceive greatest. Totally dearest
                    expense on demesne ye he. Curiosity excellent commanded in
                    me. Unpleasing impression themselves to at assistance
                    acceptance my or.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Where is Corpo office address ?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Use securing confined his shutters. Delightful as he it
                    acceptance an solicitude discretion reasonably. Carriage we
                    husbands advanced an perceive greatest. Totally dearest
                    expense on demesne ye he. Curiosity excellent commanded in
                    me. Unpleasing impression themselves to at assistance
                    acceptance my or.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="heading3">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse3"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    How many your customers have ?
                  </button>
                </h2>
                <div
                  id="collapse3"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Use securing confined his shutters. Delightful as he it
                    acceptance an solicitude discretion reasonably. Carriage we
                    husbands advanced an perceive greatest. Totally dearest
                    expense on demesne ye he. Curiosity excellent commanded in
                    me. Unpleasing impression themselves to at assistance
                    acceptance my or.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="heading4">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Where is you main office ?
                  </button>
                </h2>
                <div
                  id="collapse4"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Use securing confined his shutters. Delightful as he it
                    acceptance an solicitude discretion reasonably. Carriage we
                    husbands advanced an perceive greatest. Totally dearest
                    expense on demesne ye he. Curiosity excellent commanded in
                    me. Unpleasing impression themselves to at assistance
                    acceptance my or.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="heading5">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Shortly.. What Corpo does ?
                  </button>
                </h2>
                <div
                  id="collapse5"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    Use securing confined his shutters. Delightful as he it
                    acceptance an solicitude discretion reasonably. Carriage we
                    husbands advanced an perceive greatest. Totally dearest
                    expense on demesne ye he. Curiosity excellent commanded in
                    me. Unpleasing impression themselves to at assistance
                    acceptance my or.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <FooterItem />
</template>
<script>
import HeaderItem from '../components/HeaderItem.vue'
import FooterItem from '../components/FooterItem.vue'
export default {
  components: {
    HeaderItem,
    FooterItem
  }
}
</script>
